





























































































































import { FrontendNotify, getFrontendNotify, removeNotify } from '@/includes/logic/ChannelSubscriptionNotifies/ChannelSubscriptionNotifiesService';
import AddEntityCard from './AddEntityCard.vue';
import { LimitedSubscriptionConfig, LimitedSubscriptionType } from "@/includes/types/Channel.types";

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue';
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue';
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types';
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue';
import ActionCardAction from 'piramis-base-components/src/shared/modules/BotFeedback/AutoReplyActions/components/ActionCardAction.vue';
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import { SelectOption } from "piramis-base-components/src/logic/types";
import {
  AvailableButtonsTypes
} from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types";
import TimePickerInput from 'piramis-base-components/src/components/Pi/fields/TimePickerInput/TimePickerInput.vue';

import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import Vue from "vue";

@Component({
  data() {
    return {
      StepUnit
    }
  },
  components: {
    ConfigField,
    EmptyData,
    TimeUnitInput,
    TimePickerInput,
    ActionCardAction,
    AddEntityCard,
  }
})
export default class ChannelSubscriptionNotifies extends Mixins(ModelSetter) {
  @PropSync('frontendNotifies', { type: Array, default: () => [], required: true }) notifies!: Array<FrontendNotify>

  @PropSync('subscriptionConfig') limitedSubscriptionConfig!: LimitedSubscriptionConfig

  @Prop({ type: Array, default: () => [], required: true }) actionEditorButtons!: Array<AvailableButtonsTypes>

  @Prop({ type: Function, required: true }) flowOptions!: Promise<Array<SelectOption>>

  @Prop() subscriptionType!: LimitedSubscriptionType

  addRawNotify() {
    const notify = getFrontendNotify()

    this.notifies.push(notify)

    this.$nextTick(() => {
      this.SCROLL_TO_NOTIFY(notify.guid)
    })
  }

  SCROLL_TO_NOTIFY(guid: string) {
    const refs = this.$refs[`notify-${ guid }`] as Array<Vue> | undefined

    if (Array.isArray(refs) && refs.length) {
      const el = refs[0].$el

      el.scrollIntoView({ behavior: 'smooth' })
    }
  }

  handleRemoveItem(guid: FrontendNotify['guid']): void {
    if (this.subscriptionType !== LimitedSubscriptionType.None) {
      this.notifies = removeNotify(this.notifies, guid)
    }
  }
}
