import { LimitedSubscriptionNotify } from '@/includes/types/Channel.types'
import NotifyValidationError from '@/includes/logic/ChannelSubscriptionNotifies/NotifyValidationError'
import i18n from '@/i18n/i18n'

import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { IterableItemFactory, IterableListItem } from 'piramis-base-components/src/shared/utils/IterableItemFactory'
import {
    UserActivationAction,
    UserActivationActionTypeEnum,
    getMessageActionTemplate
} from 'piramis-base-components/src/shared/modules/BotFeedback/ActivationAction'

import { cloneDeep } from "lodash";

const iterableItemFactory = new IterableItemFactory()

type FrontendNotifyValue = {
    time: number
    action: UserActivationAction
}

export type FrontendNotify = IterableListItem<FrontendNotifyValue>

export function toFrontendNotifies(rawNotifies: LimitedSubscriptionNotify | null) {
    if (rawNotifies) {
        return Object.entries(rawNotifies)
            .map<FrontendNotify>(([ time, action ]) => {
                return iterableItemFactory.create({
                    time: Number.parseInt(time),
                    action
                })
            })
    }

    return []
}

export function toRawNotifies(notifies: Array<FrontendNotify>) {
    return notifies.reduce<LimitedSubscriptionNotify>((acc, n) => {
        acc[n.value.time] = n.value.action

        return acc
    }, {})
}

export function removeNotify(notifies: Array<FrontendNotify>, guid: string) {
    return notifies.filter(n => n.guid !== guid)
}

export function getNotifyTemplate() {
    return {
        time: StepUnit.Hour,
        action: getMessageActionTemplate(i18n)
    }
}

export function getFrontendNotify(): FrontendNotify {
    return iterableItemFactory.create(cloneDeep(getNotifyTemplate()))
}

function getDuplicateNotifies(notifies: Array<FrontendNotify>) {
    const duplicatesList = notifies.filter((value, _, arr) => {
        return arr.some(a => a.guid !== value.guid && a.value.time === value.value.time)
    })

    if (duplicatesList.length) {
        return duplicatesList.map(value => value.guid)
    } else {
        return null
    }
}

function getEmptyMessageNotifies(notifies: Array<FrontendNotify>) {
    const emptyMessage = notifies.find((n) => {
        return n.value.action.type === UserActivationActionTypeEnum.Message
            && !n.value.action.variants.length
            && (!n.value.action.variants[0]?.text?.trim() || !n.value.action.variants[0]?.attachments.length)
    })

    if (emptyMessage) {
        return emptyMessage.guid
    } else {
        return null
    }
}

export function validateNotifies(notifies: Array<FrontendNotify>) {
    return new Promise<void>((resolve, reject) => {
        const duplicatesCheckResult = getDuplicateNotifies(notifies);
        const messageIsNotConfiguredCheck = getEmptyMessageNotifies(notifies)

        if (duplicatesCheckResult !== null) {
            reject(new NotifyValidationError('notifies_non_unique', duplicatesCheckResult))
        }

        if (messageIsNotConfiguredCheck !== null) {
            reject(new NotifyValidationError('message_is_not_configured_error', [ messageIsNotConfiguredCheck ]))
        }

        resolve()
    })
}

